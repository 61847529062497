import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AuthService} from "./accounts/auth.service";
import {FlightSearchInterface} from "./itinerary-service.service";
import {environment} from "../../environment/environment";


@Injectable({
  providedIn: 'root'
})
export class CustomHttpService {
  public apiUrl = environment.corperate_base_url; // Replace with your API URL
  public ticketUrl = `${this.apiUrl}/tickets/`; // Replace with your API URL
  public companytUrl = `${this.apiUrl}/corperates/corperate/`; // Replace with your API URL
  public ticketUrlApprove = `${this.apiUrl}/tickets/`; // Replace with your API URL

  constructor(private http: HttpClient) {

  }



  post<T>(endpoint: string, data: any): Observable<T> {
    return this.http.post<T>(`${this.apiUrl}/${endpoint}`, data);
  }

  get(endpoint: string, data: any): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${endpoint}`, data);
  }


  getTrips(data:any = {}): Observable<any[]>{
    let params = new HttpParams();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        params = params.set(key, data[key]);
      }
    }
    return this.http
      .get(this.ticketUrl, { params: params })
      .pipe(map((data: any) => {
        return data
      }));
  }

  getBalance(companyID:any):Observable<any>{
    return this.http.get(`${this.companytUrl}${companyID}`).pipe(map(
      (data:any)=>{
        console.log("Balance Balance")
        return data
      }
    ))
  }

  approve(ticketId:string, data = {}) {
      return this.http
        .post(`${this.ticketUrl}${ticketId}/approve/`, data)
        .pipe(map((data:any) =>{
           return data
        }))
  }

  reject(ticketId:string, data = {}) {
    return this.http
      .post(`${this.ticketUrl}${ticketId}/reject/`, data)
      .pipe(map((data:any) =>{
        return data
      }))
  }


}
