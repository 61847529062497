
export const environment = {
  production: false,
  base_url              : 'http://localhost:2300/',
  itinerary_client_id: 'TakMHBmG5SggkwLeKZEMsg',
  itinerary_client_secret_key: 'zNmfHMywvo0OcmWQYik0CiuGXUQZrVF-W3Imf1ZAwAA',
  //itinerary_base_url: 'https://itinerary.buupass.com/',
  itinerary_base_url: 'https://dev.itinerary.buupass.com/',
  corperate_base_url: 'https://dev.corporateapi.buupass.com'
}

